import { ReactElement, useEffect, useState } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useParams, useSearchParams } from 'react-router-dom'
import validate from '../../busLog/validationBL'
import { Container } from '../../components/Container'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { Modal, useModal } from '../../components/Modal'
import { VinModalFailure } from '../../components/Modal/VinModalFailure'
import { CrModalFailure } from '../../components/Modal/CrModalFailure'
import { VinModalLengthFailure } from '../../components/Modal/VinModalLengthFailure'
import { useAppContext } from '../../contexts/AppContext'
import './index.styl'
import { SectionContact } from './sections/SectionContact'
import { SectionFaq } from './sections/SectionFaq'
import { SectionFormVin } from './sections/SectionFormVin'
import { SectionHpHighlights } from './sections/SectionHpHighlights'
import { SectionHpReviews } from './sections/SectionHpReviews'
import { SectionPageHeader } from './sections/SectionPageHeader'
import { SectionPartners } from './sections/SectionPartners'
import { SectionReferers } from './sections/SectionReferers'
import { SectionSeparator } from './sections/SectionSeparator'
import { SectionSteps } from './sections/SectionSteps'
import i18n from 'i18next'
import { ReferenceType } from '../../enums/ReferenceType'
import { resolveShowLanguage } from '../../translations/i18n'
import { useCookies } from 'react-cookie'
import { GoogleWidgetHP } from '../../components/GoogleWidgetHP'
import { GoogleWidgetDashboard } from '../../components/GoogleWidgetDashboard'
import { Section } from '../../components/Section'
import { VisualBlock } from '../../components/VisualBlock'
import { Title } from '../../components/Title'
import AssetsImgRar from './img/Cebia_RAR.webp'
import validationBL from '../../busLog/validationBL'
import { UtmCampaignModel } from '../../models/UtmCampaignModel'
import { StripeAlert } from '../../components/StripeAlert'

export const PageHomepage = (): ReactElement => {
    const appContext = useAppContext()
    const { open } = useModal('vinModalValidate')
    const [searchParams] = useSearchParams()
    const [isNoDataInfo, setNoDataInfo] = useState(false)
    const [isLengthError, setLengthError] = useState(false)
    const [sale, setSale] = useState('')
    const [isCrError, setCrError] = useState('')
    const vin = searchParams.get('vin') ?? searchParams.get('s_vin')
    const scoupon = searchParams.get('s_coupon')
    const sPage = searchParams.get('s_page')
    const utm_campaign = searchParams.get('utm_campaign')
    const [cookies, setCookie, removeCookie] = useCookies(['s_page'])
    const { crId } = useParams()
    const { vinHash } = useParams()

    useEffect(() => {
        if (crId && vinHash) {
            const result = validate.validateCrCode(crId, vinHash)
            result.then(result => {
                if (result.data.isOk) {
                    window.location.replace(result.data.url)
                } else {
                    setCrError(result.data.message)
                    open()
                    //window.location.assign('/')
                }
            })
        }
    }, [crId, vinHash])

    useEffect(() => {
        if (vin) {
            const valiDateFromUrl = async () => {
                const result = await validate.validateVinFromUrl(vin)
                if (result.data.noDataInfo) {
                    setNoDataInfo(true)
                    open()
                }
                if (result.data.lengthError) {
                    setLengthError(true)
                    open()
                }
                const { appModel } = appContext
                appModel.vin = vin
                appContext.storeAppModel(appModel, false)
            }
            valiDateFromUrl()
        }
    }, [vin])

    useEffect(() => {
        if (
            i18n.language === 'cz' &&
            process.env.REACT_APP_DOMAIN === 'cebia.com' &&
            document.getElementsByName('facebook-domain-verification').length === 0
        ) {
            const metaFCB = document.createElement('meta')
            metaFCB.name = 'facebook-domain-verification'
            metaFCB.content = 'mzs2gol3dctn0y51i3vawea4088xd7'
            document.head.appendChild(metaFCB)
        }
    }, [])

    useEffect(() => {
        if (sPage) {
            const expireInDate = new Date()
            expireInDate.setMinutes(expireInDate.getMinutes() + 1)
            setCookie('s_page', sPage, { expires: expireInDate, path: '/', secure: true })
        }
    }, [sPage])

    useEffect(() => {
        if (
            utm_campaign ||
            i18n.language === 'it' ||
            i18n.language === 'fr' ||
            i18n.language === 'ro'
        ) {
            const data = new UtmCampaignModel()
            data.utmCampaign = utm_campaign ?? ''
            const result = validationBL.validateUtmCampaign(data)
            result.then(result => {
                if (result.name) {
                    setSale(result.name)
                }
            })
        }
    }, [utm_campaign])

    return (
        <GoogleReCaptchaProvider
            language={i18n.language}
            reCaptchaKey="6Lc_c7odAAAAABUvK5_a7OIYns4_5KQI145LdlU3"
            scriptProps={{ async: true, defer: true, appendTo: 'head' }}
        >
            <div className="PageHomepage">
                {sale.length > 0 && (
                    <StripeAlert variant="info">
                        <span className="Icon Icon--discountTag mr-5"></span> {sale}
                    </StripeAlert>
                )}
                <Header
                    variant="light"
                    showLanguage={resolveShowLanguage()}
                    referenceType={ReferenceType.Default}
                />
                <SectionPageHeader initialView={scoupon && scoupon.length > 0 ? 'coupon' : 'vin'} />
                <SectionHpHighlights key={i18n.language} />
                <GoogleWidgetHP key={`${i18n.language}_googleWidgetHP`} hiddenUntilLarge={false} />
                <SectionSeparator className="smwMax:hidden" />
                {i18n.language == 'ro' && (
                    <Section variantPend="appended">
                        <Container>
                            <VisualBlock
                                variant="elevated"
                                withShapes={true}
                                visual={{
                                    image: {
                                        src: AssetsImgRar,
                                        alt: '',
                                    },
                                    clip: 'bottom',
                                }}
                            >
                                <Title
                                    as="h2"
                                    variantAlign="left"
                                    className="Beta mb-20 color-darkBlue"
                                >
                                    Istoricul RAR inclus
                                </Title>
                                <p>
                                    Registrul Auto Român (R.A.R) este organismul tehnic de
                                    specialitate desemnat de Ministerul Transporturilor și
                                    Infrastructurii ca autoritate competentă în domeniul vehiculelor
                                    rutiere, siguranţei rutiere, protecţiei mediului înconjurător şi
                                    asigurării calităţii.
                                </p>
                                <p>
                                    Cebia verifică fiecare vehicul în baza de date a Registrului
                                    Auto Român (RAR). Pe lângă înregistrările vehiculelor din
                                    străinătate, raportul poate conține și înregistrări de la toate
                                    inspecțiile tehnice periodice (ITP) din România. Puteți verifica
                                    data inspecției, kilometrajul și alte informații importante.
                                    Aflați mai multe despre RAR în{' '}
                                    <a
                                        href="https://ro.cebia.com/detailArticle/verificarea-rarom-vs-cebia-ghid-complet-pentru-o-achizitie-auto-inteleapta"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        acest articol
                                    </a>
                                    .
                                </p>
                            </VisualBlock>
                        </Container>
                    </Section>
                )}
                <SectionReferers />
                <SectionSeparator />
                <SectionSteps />
                <SectionPartners />
                {i18n.language !== 'al' ? (
                    <GoogleWidgetDashboard
                        key={`${i18n.language}_googleWidgetDashboard`}
                        showTitle={true}
                    />
                ) : (
                    <SectionHpReviews />
                )}
                <SectionSeparator />
                <SectionContact />
                <SectionFaq />
                <SectionFormVin />
                <Footer />
                <Modal id="vinModalValidate">
                    <Container>
                        {isNoDataInfo && <VinModalFailure></VinModalFailure>}
                        {isLengthError && <VinModalLengthFailure></VinModalLengthFailure>}
                        {isCrError?.length > 0 && (
                            <CrModalFailure message={isCrError}></CrModalFailure>
                        )}
                    </Container>
                </Modal>
            </div>
        </GoogleReCaptchaProvider>
    )
}
