import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { Title } from '../../../../components/Title'
import { Logo } from '../../../../components/Logo'
import AssetsImgAllianz from './img/allianz.svg'
import AssetsImgCpp from './img/cpp.svg'
import AssetsImgGcp from './img/gcp.svg'
import AssetsImgKooperativa from './img/kooperativa.svg'
import AssetsImgCarvago from './img/carvago.svg'
import AssetsImgSkodaPlus from './img/skoda_plus.svg'
import AssetsImgUniqa from './img/uniqa.svg'
import AssetsImgCarmatik from './img/Carmatik.svg'
import AssetsImgGenerali from './img/Generali.svg'
import AssetsImgVIG from './img/Vienna_Insurance_Group.svg'
import AssetsImgAures from './img/logo-aures.svg'
import AssetsImgInstituteUA from './img/logo-institut-ua.png'
import AssetsImgWebex from './img/logo-webex24.png'
import AssetsImgWestAutoHub from './img/logo-west-auto-hub.png'
import AssetsImgUnicredit from './img/unicredit_leasing.svg'
import AssetsImgAutonom from './img/autonom.svg'
import AssetsImgBavariaDirect from './img/BavariaDirekt.svg'
import AssetsImgDasWeltAuto from './img/dasWeltAuto.svg'
import AssetsImgHelvetia from './img/Helvetia.svg'
import AssetsImgLMVVersicherung from './img/LVM_Versicherung.svg'
import AssetsImgVWE from './img/VWE.svg'

import { Translate, _, __ } from '../../../../translations/CebiaTranslator'
import { i18n } from '../../../../translations/i18n'

export const SectionPartners = (): ReactElement => {
    const tran = new Translate()
    interface dataLogo {
        id: number
        image: string
        alt: string
        className?: string
        href?: string
    }

    const logoDefinition: Record<string, dataLogo[]> = {
        cz: [
            { id: 1, image: AssetsImgGcp, alt: tran.GetText('Logo Generali Česká spořitelna') },
            { id: 2, image: AssetsImgKooperativa, alt: tran.GetText('Logo Kooperativa') },
            { id: 3, image: AssetsImgCpp, alt: 'Logo Česká podnikatelská pojišťovna' },
            { id: 4, image: AssetsImgAllianz, alt: 'Allianz' },
            { id: 5, image: AssetsImgUniqa, alt: 'Logo uniqa' },
            { id: 6, image: AssetsImgAures, alt: 'Logo Aures' },
        ],
        sk: [
            { id: 1, image: AssetsImgGcp, alt: tran.GetText('Logo Generali Česká spořitelna') },
            { id: 2, image: AssetsImgKooperativa, alt: tran.GetText('Logo Kooperativa') },
            { id: 3, image: AssetsImgCpp, alt: 'Logo Česká podnikatelská pojišťovna' },
            { id: 4, image: AssetsImgAllianz, alt: 'Allianz' },
            { id: 5, image: AssetsImgUniqa, alt: 'Logo uniqa' },
        ],
        de: [
            { id: 1, image: AssetsImgVWE, alt: 'Logo VWE' },
            { id: 2, image: AssetsImgDasWeltAuto, alt: 'Logo Das Welt Auto' },
            { id: 3, image: AssetsImgAllianz, alt: 'Allianz' },
        ],
        en: [
            { id: 1, image: AssetsImgGcp, alt: tran.GetText('Logo Generali Česká spořitelna') },
            { id: 2, image: AssetsImgKooperativa, alt: tran.GetText('Logo Kooperativa') },
            { id: 3, image: AssetsImgCpp, alt: 'Logo Česká podnikatelská pojišťovna' },
            { id: 4, image: AssetsImgAllianz, alt: 'Allianz' },
            { id: 5, image: AssetsImgUniqa, alt: 'Logo uniqa' },
        ],
        ro: [
            { id: 1, image: AssetsImgUnicredit, alt: 'Unicredit Leasing' },
            { id: 2, image: AssetsImgAutonom, alt: 'Autonom' },
            { id: 3, image: AssetsImgGenerali, alt: 'Generali' },
            { id: 4, image: AssetsImgVIG, alt: 'Vienna Insurance Group', className: 'vigImage' },
            { id: 5, image: AssetsImgAllianz, alt: 'Allianz' },
            { id: 6, image: AssetsImgCarmatik, alt: 'Logo Carmatik' },
        ],
        al: [
            { id: 1, image: AssetsImgGcp, alt: tran.GetText('Logo Generali Česká spořitelna') },
            { id: 2, image: AssetsImgKooperativa, alt: tran.GetText('Logo Kooperativa') },
            { id: 3, image: AssetsImgCpp, alt: 'Logo Česká podnikatelská pojišťovna' },
            { id: 4, image: AssetsImgAllianz, alt: 'Allianz' },
            { id: 5, image: AssetsImgUniqa, alt: 'Logo uniqa' },
        ],
        uk: [
            {
                id: 1,
                image: AssetsImgWestAutoHub,
                alt: tran.GetText('Logo West Auto Hub'),
                href: 'https://wah.ua/en',
            },
            {
                id: 2,
                image: AssetsImgInstituteUA,
                alt: tran.GetText('Logo Institute UA'),
                href: 'https://eauto.org.ua/',
            },
            {
                id: 3,
                image: AssetsImgWebex,
                alt: 'Logo Webex 24',
                href: 'https://www.webex24.com/',
            },
        ],
        it: [
            { id: 1, image: AssetsImgGenerali, alt: 'Generali' },
            { id: 2, image: AssetsImgAllianz, alt: 'Allianz' },
            { id: 3, image: AssetsImgCarvago, alt: 'Carvago' },
            { id: 4, image: AssetsImgSkodaPlus, alt: 'Skoda plus' },
            { id: 5, image: AssetsImgUniqa, alt: 'Logo uniqa' },
        ],
        fr: [
            { id: 1, image: AssetsImgGenerali, alt: 'Generali' },
            { id: 2, image: AssetsImgAllianz, alt: 'Allianz' },
            { id: 3, image: AssetsImgCarvago, alt: 'Carvago' },
            { id: 4, image: AssetsImgSkodaPlus, alt: 'Skoda plus' },
            { id: 6, image: AssetsImgDasWeltAuto, alt: 'Das Welt Auto' },
        ],
        nl: [
            { id: 1, image: AssetsImgVWE, alt: tran.GetText('Logo VWE') },
            { id: 2, image: AssetsImgAllianz, alt: tran.GetText('Allianz') },
            { id: 3, image: AssetsImgBavariaDirect, alt: tran.GetText('Logo Bavaria Direct') },
            { id: 4, image: AssetsImgHelvetia, alt: tran.GetText('Logo Helvetia') },
            { id: 5, image: AssetsImgDasWeltAuto, alt: tran.GetText('Logo Das Welt Auto') },
        ],
    }
    return (
        <Section className="SectionPartners" variant="medium">
            <Container>
                <Title className="Beta color-darkBlue mb-40" as="h2" variant="withDash">
                    {tran.GetText('Profesionálové využívají služby Cebia')}
                </Title>

                <div className="SectionPartners-gridWrap">
                    <div className="Grid Grid--alignCenter gap-80 xl:gap-100 gmb-30 md:gmb-30 justify-center">
                        {(i18n?.language?.length ?? 0) > 0 &&
                            logoDefinition[i18n.language.toLowerCase()] &&
                            logoDefinition[i18n.language.toLowerCase()].map(listValue => {
                                return (
                                    <div
                                        className="SectionPartners-gridCell Grid-cell w-1/2 smw:w-1/3 md:w-1/4 lg:w-2/12"
                                        key={listValue.id.toString()}
                                    >
                                        <Logo
                                            src={listValue.image}
                                            alt={listValue.alt}
                                            className={listValue.className}
                                            href={listValue.href}
                                        />
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </Container>
        </Section>
    )
}
