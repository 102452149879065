import './index.styl'
import { ReactElement, useEffect, useState } from 'react'
import { Translate, _, __ } from '../../translations/CebiaTranslator'
import { FooterReg } from '../../components/Footer/footerReg'
import { useSearchParams } from 'react-router-dom'
import { useAppContext } from '../../contexts/AppContext'
import { Container } from '../../components/Container'
import { getRowInfoFromModel, PayedRegInfo } from '../../components/PayedRegInfo'
import { PayedRegRowInfo } from '../../components/PayedRegInfo'
import { Button } from '../../components/Button'
import { PayedRegInfoHeader } from '../../components/PayedRegInfoHeader'
import { PayedRegInfoExplanatory } from '../../components/PayedRegInfoExplanatory.tsx'
import { PayedRegInfoForm } from '../../components/PayedRegInfoForm'
import { getActivateCoupon, getInfo } from '../../busLog/payedRegBL'
import { PayedRegCarInfo } from '../../components/PayedRegCarInfo'
import { PayedJsonResult } from '../../models/DetailPayedResults/payedJsonResult'
import { getOverDesc } from '../../models/PayedReg/DetailPayedRegItemDefinitionModel'
import { VehicleInfoControlData } from '../../models/DetailPayedResults/VehicleInfo/vehicleInfoControlModel'
import { PayedRegHeader } from '../../components/PayedRegHeader'
import { confirm } from 'react-confirm-box'
import { options } from '../../components/ConfirmBox'
import { TaxiControlData } from '../../models/DetailPayedResults/Taxi/taxiControlModel'
import { RokvyControlData } from '../../models/DetailPayedResults/Rokvy/rokvyControlModel'
import { CheckLeaseControlData } from '../../models/DetailPayedResults/CheckLeas/checkLeaseControlModel'
import { CallingActionControlData } from '../../models/DetailPayedResults/CallingAction/callingActionControlModel'
import { TheftControlData } from '../../models/DetailPayedResults/Theft/theftControlModel'
import { SignalRUrlType } from '../../enums/SignalRUrlEnum'
import { useSignalRConnection } from '../../contexts/SignalR/SignalRHook'
import { SignalRState } from '../../contexts/SignalR/SignalRSetting'
import { TextUtils } from '../../utils/textUtils'
import { AtrSection } from '../../enums/AtrSectionEnum'
import { LanguageType } from '../../enums/LanguageType'
import parse from 'html-react-parser'

export type PagePayedReg = {
    type?: 'ov' | 'cr'
}

export const PagePayedReg = ({ type }: PagePayedReg): ReactElement => {
    const [searchParams] = useSearchParams()
    const dotaz_id = searchParams.get('dotaz_id')
    const appContext = useAppContext()
    const [allLoaded, setAllLoaded] = useState<boolean>(false)
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [payedBaseModel, setPayedBaseModel] = useState<PayedRegRowInfo[]>([])
    const [tachoResult, setTachoResult] = useState<PayedJsonResult>()
    const [vehicleInfoControlResult, setVehicleInfoControlResult] =
        useState<VehicleInfoControlData>()
    const [taxiControlResult, setTaxiControlResult] = useState<TaxiControlData>()
    const [rokVyControlResult, setRokVyControlResult] = useState<RokvyControlData>()
    const [theftControlResult, setTheftControlResult] = useState<TheftControlData>()
    const [checkLeaseControlResult, setCheckLeaseControlResult] = useState<CheckLeaseControlData>()
    const [callingActionControlResult, setCallingActionControlResult] =
        useState<CallingActionControlData>()

    const [viewType, setType] = useState(type ?? 'ov')

    const urlHash = window.location.hash.replace('#', '')
    const tran = new Translate()
    const textUtils = new TextUtils()

    const models = getOverDesc()
    const {
        openConnection,
        bufferedData,
        closeConnection,
        connectionId,
        hubState,
        canCloseConnection,
    } = useSignalRConnection({
        url: SignalRUrlType.DashBoard,
    })
    const { retVal, refetch, isLoading } = getInfo(dotaz_id!, connectionId ?? '')

    const activateCoupon = () => {
        getActivateCoupon(retVal!.viewId).then(() => {
            window.location.assign('/payed/detail/' + retVal?.viewId)
        })
    }

    const disableButton = () => {
        const result =
            !retVal || retVal.isCoupon === 1 || (retVal.atrStatus !== 1 && retVal.atrStatus !== 2)
        if (!result) setIsDisabled(result)
        return result
    }

    useEffect(() => {
        if (retVal && hubState === SignalRState.HubClosed) appContext.setLoading(false)
        disableButton()
    }, [retVal, hubState])

    useEffect(() => {
        if (!retVal) appContext.setLoading(true)
        if (urlHash) setType(urlHash === 'cr' ? 'cr' : 'ov')
    }, [])

    useEffect(() => {
        if (hubState === SignalRState.HubCreated) {
            appContext.setLoading(true)
            openConnection()
        }
        if (hubState === SignalRState.HubClosed) {
            setAllLoaded(true)
        }
    }, [hubState, ''])

    useEffect(() => {
        if ((bufferedData?.length ?? 0) > 0) {
            bufferedData.forEach(element => {
                const recieved = element as PayedJsonResult
                if (recieved.sectionId === AtrSection.Tachos) {
                    setTachoResult(recieved)
                }
                if (recieved && recieved.sectionId) {
                    const model = models.find(x => x.SectionId == recieved.sectionId)
                    if (model) {
                        const row = getRowInfoFromModel(model, recieved, textUtils, tran)
                        setPayedBaseModel(y => {
                            const newArr = y.filter(item => item.sectionId !== row.sectionId)
                            const ordered = [...newArr, ...[row]].sort((a, b) => a.order - b.order)
                            return ordered
                        })
                    } else if (recieved.sectionId === AtrSection.CarInfo) {
                        if (recieved.vehicleInfoControlResult) {
                            setVehicleInfoControlResult(
                                recieved.vehicleInfoControlResult ?? new VehicleInfoControlData(),
                            )
                        }
                        if (recieved.callingActionControlResult) {
                            setCallingActionControlResult(
                                recieved.callingActionControlResult ??
                                    new CallingActionControlData(),
                            )
                        }
                    } else if (recieved.sectionId === AtrSection.CarHistory) {
                        if (recieved.taxiControlResult) {
                            setTaxiControlResult(
                                recieved.taxiControlResult ?? new TaxiControlData(),
                            )
                        }
                        if (recieved.rokvyControlResult) {
                            setRokVyControlResult(
                                recieved.rokvyControlResult ?? new RokvyControlData(),
                            )
                        }
                    } else if (recieved.sectionId === AtrSection.CarSecurity) {
                        if (recieved.checkLeaseControlResult) {
                            setCheckLeaseControlResult(
                                recieved.checkLeaseControlResult ?? new CheckLeaseControlData(),
                            )
                        }
                        if (recieved.theftControlResult) {
                            setTheftControlResult(
                                recieved.theftControlResult ?? new TheftControlData(),
                            )
                        }
                    }
                }
            })
        }
        if (canCloseConnection(bufferedData)) {
            closeConnection()
        }
    }, [bufferedData])

    useEffect(() => {
        if (retVal && retVal.isValid !== 1) {
            window.location.assign('/')
        }
    }, [retVal])

    useEffect(() => {
        window.location.hash = viewType
    }, [viewType])

    const tmpText = tran.GetText(
        'VAROVÁNÍ: Může se jednat o <strong>chybné VIN</strong>. Proveďte kontrolu VIN, případně <a href="mailto:rokvy@cebia.cz?subject=Informační podpora systému-ATR identifikace&body=VIN: {0}">kontaktujte podporu.</a>',
    )

    const tmpTextCr = tran.GetText(
        'VAROVÁNÍ: Cebia REPORT nelze vytvořit. Nenalezen rok výroby. <a href="mailto:rokvy@cebia.cz?subject=Informační podpora systému-ATR rokvy&body=VIN: {0}">kontaktujte podporu.</a>.',
    )

    return (
        <>
            <PayedRegHeader />
            <div className="PagePayedReg">
                {/* <LoadArea
                    variant="fullscreen"
                    isLoading={payedBaseModel.length < 3 || !retVal}
                    loader={{ variant: 'bars', size: 'big' }}
                    hideBackGround={true}
                    text={tran.GetText('Načítáme data')}
                ></LoadArea> */}
                <PayedRegCarInfo carInfo={retVal} />
                {(retVal?.countryId === LanguageType.CZ ||
                    retVal?.countryId === LanguageType.SK) && (
                    <Container variant="medium">
                        <div className="Grid">
                            <div className="Grid-cell smw:w-full md:w-6/12 lg:w-6/12 justify-center pb-10 pl-10 pr-10">
                                <Button
                                    className="w-full h-10"
                                    type="button"
                                    as="button"
                                    variantColor={viewType === 'ov' ? 'blue' : 'lightBlue'}
                                    onClick={() => {
                                        setType('ov')
                                    }}
                                >
                                    {tran.GetText('HISTORIE VOZIDLA')}
                                </Button>
                            </div>
                            <div className="Grid-cell smw:w-full md:w-6/12 lg:w-6/12 justify-center pb-10 pl-10 pr-10">
                                <Button
                                    className="w-full h-10"
                                    as="button"
                                    type="button"
                                    variantColor={viewType === 'cr' ? 'blue' : 'lightBlue'}
                                    onClick={() => {
                                        setType('cr')
                                    }}
                                >
                                    {tran.GetText('Cebia REPORT')}
                                </Button>
                            </div>
                        </div>
                    </Container>
                )}

                <Container variant="medium" className="pb-50">
                    {viewType === 'ov' && retVal && !retVal?.carModel && (
                        <div className="Grid-cell mt-10 mb-10">
                            <div className="warning-row">
                                <span className="Icon Icon--alert"></span>
                                <label className="color-red ml-10">
                                    {parse(tmpText.replace('{0}', retVal?.carVin ?? ''))}
                                </label>
                            </div>
                        </div>
                    )}
                    {viewType === 'ov' && (
                        <PayedRegInfoHeader
                            viewType={{
                                type: viewType,
                            }}
                            carinfo={retVal}
                        />
                    )}

                    {viewType === 'ov' &&
                        payedBaseModel.map((item, index) => (
                            <PayedRegInfo
                                key={'payedRegInfos_' + index}
                                state={item.state}
                                mainText={item.mainText}
                                countText={item.countText}
                                periodText={item.periodText}
                                periodVariant={item.periodVariant}
                                periodPrefix={item.periodPrefix}
                                helpText={item.helpText}
                                visualIcon={item.visualIcon}
                                sectionId={0}
                            ></PayedRegInfo>
                        ))}
                    {viewType === 'ov' && (rokVyControlResult || !rokVyControlResult) && retVal && (
                        <PayedRegInfo
                            key={'30'}
                            state={!rokVyControlResult ? 'inactive' : 'active'}
                            mainText={tran.GetText('Kontrola stáří a původu')}
                            countText={!rokVyControlResult ? '' : tran.GetText('K dispozici')}
                            periodText=""
                            periodVariant="text"
                            periodPrefix=""
                            helpText={tran.GetText(
                                'Ověříme pro Vás skutečný rok výroby, což může mít podstatný dopad na současnou i budoucí cenu vozidla. Rok výroby se v technických průkazech již neuvádí. Zapisuje se pouze datum 1. registrace. To se ale od roku výroby může lišit až o 2 roky, ale není výjimkou ani 6 let. Přitom jeden rok omlazení může v ceně vozidla znamenat i několik desítek tisíc korun, které zaplatíte navíc.   <br />U většiny vozidel se také dozvíte, datum 1. registrace a pro jakou zemi bylo vozidlo původně vyrobeno.',
                            )}
                            sectionId={30}
                            visualIcon={{
                                name: 'calendarCar',
                            }}
                        ></PayedRegInfo>
                    )}
                    {viewType === 'ov' && taxiControlResult && (
                        <PayedRegInfo
                            key={'31'}
                            state={taxiControlResult === null ? 'inactive' : 'active'}
                            mainText={tran.GetText('Kontrola taxi')}
                            countText={
                                taxiControlResult === null ? '' : tran.GetText('K dispozici')
                            }
                            periodText=""
                            periodVariant="text"
                            periodPrefix=""
                            helpText={tran.GetText(
                                'Prověření v databázích vozidel provozovaných jako taxislužba. V placené části se dozvíte, zda vůz byl a nebo stále je provozován jako taxi.',
                            )}
                            sectionId={31}
                            visualIcon={{
                                name: 'glassCar',
                            }}
                        ></PayedRegInfo>
                    )}
                    {viewType === 'ov' && (
                        <PayedRegInfo
                            key={'33'}
                            state={'active'}
                            mainText={tran.GetText('Kontrola odcizení')}
                            countText={tran.GetText('až 12 registrů')}
                            periodText=""
                            periodVariant="text"
                            periodPrefix=""
                            helpText={tran.GetText(
                                'Vozidlo prověříme v aktuálně dostupných databázích odcizených vozidel.<br />Jedná se převážně o evropské databáze ze státních i nestátních zdrojů. Seznam dostupných zemí a zdrojů najdete v placené části.',
                            )}
                            sectionId={33}
                            visualIcon={{
                                name: 'thiefCar',
                            }}
                        ></PayedRegInfo>
                    )}
                    {viewType === 'ov' && checkLeaseControlResult && (
                        <PayedRegInfo
                            key={'35'}
                            state={checkLeaseControlResult === null ? 'inactive' : 'active'}
                            mainText={tran.GetText('Kontrola financování')}
                            countText={
                                checkLeaseControlResult === null ? '' : tran.GetText('K dispozici')
                            }
                            periodText=""
                            periodVariant="text"
                            periodPrefix=""
                            helpText={tran.GetText(
                                'Vozidlo prověříme v databázích finančních společností, autopůjčoven a zastaváren. V placené části lze zkontrolovat, zda vůz není zatížen financováním, zápůjčkou nebo zástavou.',
                            )}
                            sectionId={35}
                            visualIcon={{
                                name: 'tabletMoney',
                            }}
                        ></PayedRegInfo>
                    )}
                    {viewType === 'ov' && callingActionControlResult && (
                        <PayedRegInfo
                            key={'38'}
                            state={
                                !callingActionControlResult.isCallingAction ? 'inactive' : 'active'
                            }
                            mainText={tran.GetText('Svolávací akce')}
                            countText={
                                !callingActionControlResult.isCallingAction
                                    ? ''
                                    : tran.GetText('K dispozici')
                            }
                            periodText=""
                            periodVariant="text"
                            periodPrefix=""
                            helpText={tran.GetText(
                                'Zobrazíme vám seznam svolávacích akcí, které by se mohly týkat prověřovaného vozidla.',
                            )}
                            sectionId={38}
                            visualIcon={{
                                name: 'megaphone',
                            }}
                        ></PayedRegInfo>
                    )}
                    {viewType === 'ov' && vehicleInfoControlResult && (
                        <>
                            <PayedRegInfo
                                key={'payedRegInfosCR_2419'}
                                state={
                                    vehicleInfoControlResult?.vehicleIdentificationSubCaption ===
                                    tran.GetText('Není k dispozici')
                                        ? 'inactive'
                                        : 'active'
                                }
                                mainText={tran.GetText('Seznam výbavy dle výrobce')}
                                countText={
                                    vehicleInfoControlResult?.vehicleIdentificationSubCaption ===
                                    tran.GetText('Není k dispozici')
                                        ? ''
                                        : vehicleInfoControlResult?.vehicleIdentificationSubCaption ??
                                          ''
                                }
                                periodText=""
                                periodVariant="text"
                                periodPrefix=""
                                helpText={tran.GetText(
                                    'Zobrazíme Vám výpis výbavy vozidla dle specifikace z jeho výroby. Můžete si tak porovnat současnou výbavu vozidla s tou, kterou udává výrobce. Pozor: pokud například výrobce uvádí v parametrech vozidla pravostranné řízení, kožené čalounění nebo střešní okno a Vaše vozidlo má řízení na levé straně, čalounění z látky nebo nemá střešní okno – pravděpodobně nejde o totéž vozidlo, ale může se jednat o vozidlo odcizené a předělané!  Při jakékoliv nesrovnalosti byste měli věnovat zvýšenou pozornost dalšímu fyzickému prověření vozidla a předejít tak špatnému nákupu vozidla, které může pocházet z trestné činnosti.',
                                )}
                                sectionId={2419}
                                visualIcon={{
                                    name: 'gearCar',
                                }}
                            ></PayedRegInfo>

                            <PayedRegInfo
                                key={'payedRegInfosCR_2417'}
                                state={
                                    vehicleInfoControlResult?.vehicleSecurity?.length === 0
                                        ? 'inactive'
                                        : 'active'
                                }
                                mainText={tran.GetText('Kontrola zabezpečení vozidla')}
                                countText={
                                    vehicleInfoControlResult?.vehicleSecurity?.length === 0
                                        ? ''
                                        : tran.GetText('K dispozici')
                                }
                                periodText=""
                                periodVariant="text"
                                periodPrefix=""
                                helpText={tran.GetText(
                                    'Vaše vozidlo je zabezpečeno formou pískování nebo leptání skel, případně na vozidle mohlo být provedeno značení dílů karoserie. Sdělíme Vám vyznačený kód a datum registrace.',
                                )}
                                sectionId={2417}
                                visualIcon={{
                                    name: 'carLock',
                                }}
                            ></PayedRegInfo>

                            <PayedRegInfo
                                key={'payedRegInfosCR_21'}
                                state={
                                    vehicleInfoControlResult?.vehicleTechDesc?.length === 0
                                        ? 'inactive'
                                        : 'active'
                                }
                                mainText={tran.GetText('Technický popis vozidla')}
                                countText={
                                    vehicleInfoControlResult?.vehicleTechDesc?.length === 0
                                        ? ''
                                        : tran.GetText('K dispozici')
                                }
                                periodText=""
                                periodVariant="text"
                                periodPrefix=""
                                helpText={tran.GetText(
                                    'Podle technického popisu si budete moct sami zkontrolovat důležité parametry, na jejichž základě bylo modelové provedení daného typu vozidla schváleno do provozu.   Porovnejte tyto parametry s údaji uvedenými v technickém průkazu vozidla.',
                                )}
                                sectionId={21}
                                visualIcon={{
                                    name: 'clipboard',
                                }}
                            ></PayedRegInfo>
                            <PayedRegInfo
                                key={'payedRegInfosCR_7649'}
                                state={
                                    vehicleInfoControlResult?.vehicleColor?.length === 0
                                        ? 'inactive'
                                        : 'active'
                                }
                                mainText={tran.GetText('Kontrola barvy vozidla')}
                                countText={
                                    vehicleInfoControlResult?.vehicleColor?.length === 0
                                        ? ''
                                        : tran.GetText('K dispozici')
                                }
                                periodText=""
                                periodVariant="text"
                                periodPrefix=""
                                helpText={tran.GetText(
                                    'Zobrazíme vám barvu evidovanou výrobcem vozidla a barvu po registraci vozidla v ČR, případně po registraci na Slovensku (pokud máme tyto informace k dispozici).',
                                )}
                                sectionId={7649}
                                visualIcon={{
                                    name: 'carPalete',
                                }}
                            ></PayedRegInfo>
                        </>
                    )}
                    {viewType === 'ov' && (
                        <div className="PayedRegRowInfo Grid PayedRegRowInfo--active">
                            {retVal && retVal.isCoupon === 1 && (
                                <div className="flex">
                                    <div className="Grid-cell smw:w-full md:w-full lg:w-10/12 justify-end xs-offset-normal wrap">
                                        {tran.GetHtml(
                                            'Prověření již bylo <strong className="ml-5">provedeno</strong>',
                                        )}
                                    </div>
                                    <div className="Grid-cell smw:w-2/12 md:w-2/12 lg:w-2/12 justify-end toEnd">
                                        <Button
                                            as="button"
                                            variantSize="small"
                                            type="button"
                                            onClick={() =>
                                                window.location.assign(
                                                    '/payed/detail/' + retVal?.viewId,
                                                )
                                            }
                                        >
                                            {tran.GetText('ZOBRAZIT VÝPIS')}
                                        </Button>
                                    </div>
                                    <div className="navbar-fixed">
                                        <div className="Grid-cell ml-20 w-7/12 lg:w-10/12 justify-end xs-offset-normal wrap">
                                            {tran.GetHtml(
                                                'Prověření již bylo <strong className="ml-5">provedeno</strong>',
                                            )}
                                        </div>
                                        <div className="Grid-cell mr-20 w-5/12 lg:w-2/12 justify-end">
                                            <Button
                                                as="button"
                                                variantSize="small"
                                                type="button"
                                                onClick={() =>
                                                    window.location.assign(
                                                        '/payed/detail/' + retVal?.viewId,
                                                    )
                                                }
                                            >
                                                {tran.GetText('ZOBRAZIT VÝPIS')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {retVal && retVal.isCoupon !== 1 && retVal.atrStatus === 0 && (
                                <>
                                    <div className="Grid-cell smw:w-full md:w-full lg:w-10/12 justify-end xs-offset-normal">
                                        <div className="warning-row">{retVal.errorMessage}</div>
                                    </div>
                                    <div className="navbar-fixed">
                                        <div className="Grid-cell ml-20 w-7/12 lg:w-10/12 justify-end xs-offset-normal ">
                                            <div className="warning-row">{retVal.errorMessage}</div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="flex">
                                {retVal && allLoaded && retVal.isCoupon !== 1 && (
                                    <div className="Grid-cell smw:w-full md:w-full lg:w-10/12 justify-end xs-offset-normal wrap">
                                        {retVal &&
                                            allLoaded &&
                                            retVal.isCoupon !== 1 &&
                                            retVal.atrStatus === 1 && (
                                                <>
                                                    {tran.GetHtml(
                                                        'Prověření <strong className="ml-5 mr-5">za poplatek</strong> dle smlouvy',
                                                    )}
                                                </>
                                            )}
                                        {retVal &&
                                            allLoaded &&
                                            retVal.isCoupon !== 1 &&
                                            retVal.atrStatus === 2 && (
                                                <>
                                                    {tran.GetHtml(
                                                        'Prověření <strong className="ml-5 mr-5">zdarma</strong> v rámci limitu dle smlouvy',
                                                    )}
                                                </>
                                            )}
                                    </div>
                                )}
                                {!isDisabled && (
                                    <>
                                        <div className="Grid-cell smw:w-2/12 md:w-2/12 lg:w-2/12 justify-end toEnd">
                                            <Button
                                                as="button"
                                                type="button"
                                                variantSize="small"
                                                onClick={async () => {
                                                    window.scrollTo(0, 0)
                                                    const result = await confirm(
                                                        retVal?.apcMessage ?? '',
                                                        options,
                                                    )
                                                    if (result) {
                                                        appContext.setLoading(true)
                                                        activateCoupon()
                                                    }
                                                }}
                                            >
                                                {tran.GetText('PROVĚŘIT VOZIDLO')}
                                            </Button>
                                        </div>
                                        <div className="navbar-fixed">
                                            <div className="Grid-cell ml-20 w-7/12 lg:w-10/12 justify-end xs-offset-normal wrap">
                                                {retVal &&
                                                    allLoaded &&
                                                    retVal.isCoupon !== 1 &&
                                                    retVal.atrStatus === 1 && (
                                                        <>
                                                            {tran.GetHtml(
                                                                'Prověření <strong className="ml-5 mr-5">za poplatek</strong> dle smlouvy',
                                                            )}
                                                        </>
                                                    )}
                                                {retVal &&
                                                    allLoaded &&
                                                    retVal.isCoupon !== 1 &&
                                                    retVal.atrStatus === 2 && (
                                                        <>
                                                            {tran.GetHtml(
                                                                'Prověření <strong className="ml-5 mr-5">zdarma</strong> v rámci limitu dle smlouvy',
                                                            )}
                                                        </>
                                                    )}
                                            </div>
                                            <div className="Grid-cell mr-20 w-5/12 lg:w-2/12 justify-end">
                                                <Button
                                                    as="button"
                                                    type="button"
                                                    variantSize="small"
                                                    onClick={async () => {
                                                        window.scrollTo(0, 0)
                                                        const result = await confirm(
                                                            retVal?.apcMessage ?? '',
                                                            options,
                                                        )
                                                        if (result) {
                                                            appContext.setLoading(true)
                                                            activateCoupon()
                                                        }
                                                    }}
                                                >
                                                    {tran.GetText('PROVĚŘIT VOZIDLO')}
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {viewType === 'cr' && tachoResult && !tachoResult.recordsCount && (
                        <div className="Grid-cell mt-10 mb-10">
                            <div className="warning-row">
                                <span className="Icon Icon--alert"></span>
                                <label className="color-red ml-10">
                                    {tran.GetHtml(
                                        'VAROVÁNÍ:Nelze provést <strong>kontrolu stavu tachometru</strong>. Kategorie bude mít výsledek - nedostatek informací.',
                                    )}
                                </label>
                            </div>
                        </div>
                    )}
                    {viewType === 'cr' && theftControlResult && theftControlResult.isTheft && (
                        <div className="Grid-cell mt-10 mb-10">
                            <div className="warning-row">
                                <span className="Icon Icon--alert"></span>
                                <label className="color-red ml-10">
                                    {tran.GetHtml(
                                        'VAROVÁNÍ: Cebia REPORT nelze vytvořit. Vozidlo je evidované jako <strong className="bolder">odcizené</strong>.',
                                    )}
                                </label>
                            </div>
                        </div>
                    )}
                    {viewType === 'cr' && rokVyControlResult && !rokVyControlResult.rokVy && (
                        <div className="Grid-cell mt-10 mb-10">
                            <div className="warning-row">
                                <span className="Icon Icon--alert"></span>
                                <label className="color-red ml-10">
                                    ¨{parse(tmpTextCr.replace('{0}', retVal?.carVin ?? ''))}
                                </label>
                            </div>
                        </div>
                    )}
                    {viewType === 'cr' && (
                        <PayedRegInfoHeader
                            viewType={{
                                type: viewType,
                            }}
                            carinfo={retVal}
                        />
                    )}

                    {viewType === 'cr' && (
                        <>
                            {payedBaseModel
                                .filter(x => x.crData)
                                .map((item, index) => (
                                    <PayedRegInfo
                                        key={'payedRegInfosCR_' + index}
                                        state={item.state}
                                        mainText={item.mainText}
                                        countText={item.countText}
                                        periodText={item.periodText}
                                        periodVariant={item.periodVariant}
                                        periodPrefix={item.periodPrefix}
                                        helpText=""
                                        visualIcon={item.visualIcon}
                                        sectionId={0}
                                    ></PayedRegInfo>
                                ))}
                            <PayedRegInfo
                                key={'payedRegInfosCR_5'}
                                state="active"
                                mainText={tran.GetText('Kontrola VIN')}
                                countText={tran.GetText('K dispozici')}
                                periodText=""
                                periodVariant="text"
                                periodPrefix=""
                                helpText=""
                                sectionId={5}
                                visualIcon={{
                                    name: 'plate',
                                }}
                            ></PayedRegInfo>
                        </>
                    )}
                    {viewType === 'cr' && (rokVyControlResult || !rokVyControlResult) && retVal && (
                        <PayedRegInfo
                            key={'30'}
                            state={!rokVyControlResult ? 'inactive' : 'active'}
                            mainText={tran.GetText('Kontrola roku výroby')}
                            countText={!rokVyControlResult ? '' : tran.GetText('K dispozici')}
                            periodText=""
                            periodVariant="text"
                            periodPrefix=""
                            helpText=""
                            sectionId={30}
                            visualIcon={{
                                name: 'calendarCar',
                            }}
                        ></PayedRegInfo>
                    )}
                    {viewType === 'cr' && (
                        <PayedRegInfo
                            key={'33'}
                            state={'active'}
                            mainText={tran.GetText('Kontrola odcizení v ČR a SR')}
                            countText={tran.GetText('K dispozici')}
                            periodText=""
                            periodVariant="text"
                            periodPrefix=""
                            helpText=""
                            sectionId={33}
                            visualIcon={{
                                name: 'thiefCar',
                            }}
                        ></PayedRegInfo>
                    )}
                    {viewType === 'cr' && checkLeaseControlResult && (
                        <PayedRegInfo
                            key={'35'}
                            state={checkLeaseControlResult === null ? 'inactive' : 'active'}
                            mainText={tran.GetText('Kontrola leasingu/úvěru v ČR')}
                            countText={
                                checkLeaseControlResult === null ? '' : tran.GetText('K dispozici')
                            }
                            periodText=""
                            periodVariant="text"
                            periodPrefix=""
                            helpText=""
                            sectionId={35}
                            visualIcon={{
                                name: 'tabletMoney',
                            }}
                        ></PayedRegInfo>
                    )}
                    {viewType === 'cr' &&
                        !theftControlResult?.isTheft &&
                        rokVyControlResult?.rokVy && (
                            <PayedRegInfoForm tachos={tachoResult} carinfo={retVal} />
                        )}
                </Container>
                <Container variant="medium" className="pb-50">
                    {viewType === 'ov' && (
                        <PayedRegInfoExplanatory type={viewType} showExplanatory={true} />
                    )}
                    {viewType === 'cr' && (
                        <PayedRegInfoExplanatory type={viewType} showExplanatory={true} />
                    )}
                </Container>
                <FooterReg />
            </div>
        </>
    )
}
