import { ReactElement, useEffect, useState } from 'react'
import { Container } from '../../components/Container'
import { FadedBackground } from '../../components/FadedBackground'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { PageHeader } from '../../components/PageHeader'
import { PageShape } from '../../components/PageShape'
import { Section } from '../../components/Section'
import { Shapes } from '../../components/Shapes'
import { ReferenceType } from '../../enums/ReferenceType'
import { Translate } from '../../translations/CebiaTranslator'
import { i18n, LanguageDefinition, LanguageEnum } from '../../translations/i18n'

export const PageCebiaNetRegistration = (): ReactElement => {
    const tran = new Translate()

    const paramData =
        LanguageDefinition[LanguageEnum[i18n.language as keyof typeof LanguageEnum]]?.elfsight ?? -1

    if (paramData.length === 0) window.location.assign('/')

    useEffect(() => {
        importScriptData()
    }, [paramData])

    const importScriptData = () => {
        const script = document.createElement('script')
        script.src = 'https://static.elfsight.com/platform/platform.js'
        script.async = true
        script.defer = true
        script.setAttribute('data-use-service-core', '')

        const pageCookiesText = document.getElementById('pageCelfsightId')
        if (pageCookiesText !== undefined && pageCookiesText !== null) {
            pageCookiesText.innerHTML = ''
            pageCookiesText.appendChild(script)
        }

        //document.head.appendChild(script)
    }

    return (
        <div className="PageRegistraiton">
            <PageShape sectionShape={{ variant: 'radialLeftTop' }} />

            <Header showLanguage={false} referenceType={ReferenceType.Default} />

            <PageHeader>
                <h1 className="Beta color-darkBlue">
                    {tran.GetText('Registrační formulář CebiaNet')}
                </h1>
            </PageHeader>

            <Section variantPend="appended">
                <Container>
                    <Shapes variant="2" />
                    <FadedBackground variant="small" onlyDesktop rounded>
                        <Section>
                            <Container variant="slim">
                                <div
                                    id="pageCelfsightId"
                                    className={paramData}
                                    data-elfsight-app-lazy
                                ></div>
                            </Container>
                        </Section>
                    </FadedBackground>
                </Container>
            </Section>

            <Footer />
        </div>
    )
}
