import './index.styl'
import { ReactElement } from 'react'
import classNames, { Argument } from 'classnames'

export type LoaderProps = {
    variant: 'bars' | 'dots' | 'circle' | 'circleSmall'
    size?: 'big'
    className?: Argument
}

export const Loader = ({ variant, size, className: _className }: LoaderProps): ReactElement => {
    const className = classNames(
        'Loader',
        {
            [`Loader--${variant}`]: variant,
            [`Loader--${size}`]: size,
        },
        _className,
    )
    return (
        <div className={className}>
            <span></span>
        </div>
    )
}
