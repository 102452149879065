import { Translate } from '../translations/CebiaTranslator'
import roFlag from '../assets/img/flags/ro.svg'
import czFlag from '../assets/img/flags/cz.svg'
import deFlag from '../assets/img/flags/de.svg'
import skFlag from '../assets/img/flags/sk.svg'
import usFlag from '../assets/img/flags/us.svg'
import uaFlag from '../assets/img/flags/ua.svg'
import itFlag from '../assets/img/flags/it.svg'
import frFlag from '../assets/img/flags/fr.svg'
import nlFlag from '../assets/img/flags/nl.svg'
//import alFlag from '../assets/img/flags/al.svg'

export function getCountries(tran: Translate) {
    const result = [
        {
            id: 3,
            value: 3,
            name: tran.GetText('Albánie'),
            countryName: tran.GetText('Albánie'),
            code2: 'AL',
            vat: 18.0,
            currency: 'EUR',
            countryPhonePrefix: '+355',
            icon: `${process.env.PUBLIC_URL}/flag/al.png`,
            dicLength: 12,
        },
        {
            id: 115,
            name: tran.GetText('Kosovo'),
            countryName: tran.GetText('Kosovo'),
            code2: 'XK',
            vat: 18.0,
            currency: 'EUR',
            countryPhonePrefix: '+383',
            icon: `${process.env.PUBLIC_URL}/flag/xk.png`,
            dicLength: 12,
        },
        {
            id: 133,
            name: tran.GetText('Severní Makedonie'),
            countryName: tran.GetText('Severní Makedonie'),
            code2: 'MK',
            vat: 18.0,
            currency: 'EUR',
            countryPhonePrefix: '+389',
            icon: `${process.env.PUBLIC_URL}/flag/mk.png`,
            dicLength: 12,
        },
        {
            id: 43,
            value: 43,
            name: tran.GetText('Česko'),
            countryName: tran.GetText('Česká republika'),
            code2: 'CZ',
            vat: 21.0,
            currency: 'CZK',
            countryPhonePrefix: '+420',
            icon: czFlag,
            dicLength: 12,
            minDicLength: 10,
        },
        {
            id: 157,
            name: tran.GetText('Německo'),
            countryName: tran.GetText('Německo'),
            code2: 'DE',
            vat: 21.0,
            currency: 'CZK',
            countryPhonePrefix: '',
            icon: deFlag,
            dicLength: 14,
        },
        {
            id: 184,
            name: tran.GetText('Rumunsko'),
            countryName: tran.GetText('Rumunsko'),
            code2: 'RO',
            vat: 21.0,
            currency: 'CZK',
            countryPhonePrefix: '+40',
            icon: roFlag,
            dicLength: 12,
            minDicLength: 4,
        },
        {
            id: 198,
            name: tran.GetText('Slovensko'),
            countryName: tran.GetText('Slovenská republika'),
            code2: 'SK',
            vat: 21.0,
            currency: 'CZK',
            countryPhonePrefix: '+421',
            icon: skFlag,
            dicLength: 12,
            minDicLength: 12,
        },
        {
            id: 202,
            name: tran.GetText('Spojené státy'),
            countryName: tran.GetText('Spojené státy'),
            code2: 'US',
            vat: 21.0,
            currency: 'CZK',
            countryPhonePrefix: '',
            icon: usFlag,
            dicLength: 14,
        },
        {
            id: 237,
            name: tran.GetText('Ukrajina'),
            countryName: tran.GetText('Ukrajina'),
            code2: 'UA',
            vat: 0.0,
            currency: 'UAH',
            countryPhonePrefix: '+380',
            icon: uaFlag,
            dicLength: 14,
        },
        {
            id: 90,
            name: tran.GetText('Itálie'),
            countryName: tran.GetText('Itálie'),
            code2: 'IT',
            vat: 22.0,
            currency: 'EUR',
            countryPhonePrefix: '+39',
            icon: itFlag,
            dicLength: 13,
            minDicLength: 13,
        },
        {
            id: 60,
            name: tran.GetText('Francie'),
            countryName: tran.GetText('Francie'),
            code2: 'FR',
            vat: 20.0,
            currency: 'EUR',
            countryPhonePrefix: '+33',
            icon: frFlag,
            dicLength: 13,
            minDicLength: 13,
        },
        {
            id: 163,
            name: tran.GetText('Nizozemsko'),
            countryName: tran.GetText('Nizozemsko'),
            code2: 'NL',
            vat: 21.0,
            currency: 'EUR',
            countryPhonePrefix: '+31',
            icon: nlFlag,
            dicLength: 14,
            minDicLength: 14,
        },
    ]
    return result
}
