import './index.styl'
import React, { FormEvent, ReactElement, useEffect, ReactNode } from 'react'
import classNames, { Argument } from 'classnames'
import { InputType } from './InputType'
import { Field, ErrorMessage, useField } from 'formik'
import { BubbleOpener } from '../BubbleOpener'
import { InputComponent } from './InputComponent'
import moment from 'moment'
import { RandomStringGenerator } from '../../utils/randomString'

export type InputValue = string | undefined //| number | readonly string[]
export type InputChangeHandler = (value: InputValue, event: FormEvent<HTMLInputElement>) => void
export type InputFocusHandler = (event: FormEvent<HTMLInputElement>) => void
export type InputBlurHandler = (event: FormEvent<HTMLInputElement>) => void

export type InputProps = {
    variant?: 'dark'
    variantSize?: 'desktopLarge'
    errorClassName?: string | undefined
    showErrorMessage?: boolean
    type?: InputType
    inputmode?: string
    component?: InputComponent
    id: string
    name: string
    value?: InputValue
    isInvalid?: boolean
    placeholder?: string
    required?: boolean
    className?: Argument
    maxLength?: number
    minLength?: number
    initialerror?: string
    label?: string
    children?: ReactNode
    autocompleteoff?: boolean
    labelQuestionHandler?: () => void
    onChange?: InputChangeHandler
    onFocus?: InputFocusHandler
    onBlur?: InputFocusHandler
    validate?: InputChangeHandler
}

export const Input = ({
    variant,
    variantSize,
    showErrorMessage = true,
    errorClassName,
    isInvalid = false,
    className: _className,
    onChange,
    onFocus,
    onBlur,
    validate,
    required = false,
    type = 'text',
    inputmode = '',
    component = 'input',
    placeholder,
    maxLength,
    minLength,
    children,
    autocompleteoff = false,
    labelQuestionHandler,
    ...props
}: InputProps): ReactElement => {
    const [field, meta, helpers] = useField(props.name)
    const generator = new RandomStringGenerator()
    function handlevalidate(e: any) {
        let error = ''
        if (minLength && field.value.length < minLength) {
            error = `Value must be at least ${minLength} characters long.`
        }
        if (validate) {
            validate(field.value, e)
        } else {
            helpers.setError(error) // Nastaví chybu ve Formiku
        }
    }

    function handleFocus(e: any) {
        if (onFocus) {
            onFocus(e)
        }
    }

    function handleBlur(e: any) {
        if (onBlur) {
            onBlur(e)
        }
        field.onBlur(e)
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (onChange) {
            onChange(e.target.value, e)
        }
        field.onChange(e)
    }

    const className = classNames(
        'Input',
        {
            [`Input--${variant}`]: variant,
            [`Input--${variantSize}`]: variantSize,
            [`is-empty`]: !field.value,
            [`is-invalid`]: isInvalid,
        },
        _className,
    )
    useEffect(() => {
        helpers.setError(props.initialerror)
    }, [])
    return (
        <>
            {props.label && (
                <label className="Label" htmlFor={props.name}>
                    {props.label}

                    {labelQuestionHandler && (
                        <BubbleOpener
                            className={`questioner${props.name}`}
                            onClickHandler={labelQuestionHandler}
                        />
                    )}
                </label>
            )}
            {children}

            <Field
                autoComplete={autocompleteoff ? generator.Generate(15, 'alpha') : 'on'}
                className={`${className} ${meta.error || props.initialerror ? 'is-invalid' : ''}`}
                type={type}
                inputMode={inputmode}
                component={component}
                value={
                    field.value
                        ? type == 'date'
                            ? moment(field.value).format('YYYY-MM-DD')
                            : field.value
                        : ''
                }
                required={required}
                placeholder={placeholder}
                maxLength={maxLength}
                minLength={minLength}
                onBlur={(e: React.FocusEvent) => handleBlur(e)}
                onFocus={(e: React.FocusEvent) => handleFocus(e)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                validate={(e: React.ChangeEvent<any>) => handlevalidate(e)}
                {...props}
            />

            {showErrorMessage && (
                <ErrorMessage
                    name={props.name}
                    component="div"
                    className={`InputError ${errorClassName ?? ''}`}
                />
            )}
        </>
    )
}
